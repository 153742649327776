@font-face {
    font-family: 'unicons';
    src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/unicons.eot');
    src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/unicons.eot') format('embedded-opentype'),
         url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/unicons.woff2') format('woff2'),
         url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/unicons.woff') format('woff'),
         url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/unicons.ttf') format('truetype'),
         url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/1462889/unicons.svg') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'unicons';
      src: url('../font/unicons.svg?50911815#unicons') format('svg');
    }
  }
  */
   
   [class^="uil-"]:before, [class*=" uil-"]:before {
    font-family: "unicons";
    font-style: normal;
    font-weight: normal;
    speak: none;
   
    display: inline-block;
    text-decoration: inherit;
    text-align: center;
    /* opacity: .8; */
   
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
   
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
   
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
   
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
   
  .uil-0-plus:before { content: '\e800'; } /* 'î €' */
  .uil-10-plus:before { content: '\e801'; } /* 'î ' */
  .uil-12-plus:before { content: '\e802'; } /* 'î ‚' */
  .uil-13-plus:before { content: '\e803'; } /* 'î ƒ' */
  .uil-16-plus:before { content: '\e804'; } /* 'î „' */
  .uil-17-plus:before { content: '\e805'; } /* 'î …' */
  .uil-18-plus:before { content: '\e806'; } /* 'î †' */
  .uil-21-plus:before { content: '\e807'; } /* 'î ‡' */
  .uil-3-plus:before { content: '\e808'; } /* 'î ˆ' */
  .uil-500px:before { content: '\e809'; } /* 'î ‰' */
  .uil-6-plus:before { content: '\e80a'; } /* 'î Š' */
  .uil-abacus:before { content: '\e80b'; } /* 'î ‹' */
  .uil-accessible-icon-alt:before { content: '\e80c'; } /* 'î Œ' */
  .uil-adjust-alt:before { content: '\e80d'; } /* 'î ' */
  .uil-adjust-circle:before { content: '\e80e'; } /* 'î Ž' */
  .uil-adjust-half:before { content: '\e80f'; } /* 'î ' */
  .uil-adjust:before { content: '\e810'; } /* 'î ' */
  .uil-adobe:before { content: '\e811'; } /* 'î ‘' */
  .uil-airplay:before { content: '\e812'; } /* 'î ’' */
  .uil-align-alt:before { content: '\e813'; } /* 'î “' */
  .uil-align-center-alt:before { content: '\e814'; } /* 'î ”' */
  .uil-align-center-h:before { content: '\e815'; } /* 'î •' */
  .uil-align-center-justify:before { content: '\e816'; } /* 'î –' */
  .uil-align-center-v:before { content: '\e817'; } /* 'î —' */
  .uil-align-center:before { content: '\e818'; } /* 'î ˜' */
  .uil-align-justify:before { content: '\e819'; } /* 'î ™' */
  .uil-align-left-justify:before { content: '\e81a'; } /* 'î š' */
  .uil-align-left:before { content: '\e81b'; } /* 'î ›' */
  .uil-align-letter-right:before { content: '\e81c'; } /* 'î œ' */
  .uil-align-right-justify:before { content: '\e81d'; } /* 'î ' */
  .uil-align-right:before { content: '\e81e'; } /* 'î ž' */
  .uil-align:before { content: '\e81f'; } /* 'î Ÿ' */
  .uil-ambulance:before { content: '\e820'; } /* 'î  ' */
  .uil-analysis:before { content: '\e821'; } /* 'î ¡' */
  .uil-analytics:before { content: '\e822'; } /* 'î ¢' */
  .uil-anchor:before { content: '\e823'; } /* 'î £' */
  .uil-android-phone-slash:before { content: '\e824'; } /* 'î ¤' */
  .uil-angle-double-down:before { content: '\e825'; } /* 'î ¥' */
  .uil-angle-double-left:before { content: '\e826'; } /* 'î ¦' */
  .uil-angle-double-right:before { content: '\e827'; } /* 'î §' */
  .uil-angle-double-up:before { content: '\e828'; } /* 'î ¨' */
  .uil-angle-down:before { content: '\e829'; } /* 'î ©' */
  .uil-angle-left:before { content: '\e82a'; } /* 'î ª' */
  .uil-angle-right-b:before { content: '\e82b'; } /* 'î «' */
  .uil-angle-right:before { content: '\e82c'; } /* 'î ¬' */
  .uil-angle-up:before { content: '\e82d'; } /* 'î ­' */
  .uil-angry:before { content: '\e82e'; } /* 'î ®' */
  .uil-ankh:before { content: '\e82f'; } /* 'î ¯' */
  .uil-annoyed-alt:before { content: '\e830'; } /* 'î °' */
  .uil-annoyed:before { content: '\e831'; } /* 'î ±' */
  .uil-apple:before { content: '\e832'; } /* 'î ²' */
  .uil-apps:before { content: '\e833'; } /* 'î ³' */
  .uil-archive-alt:before { content: '\e834'; } /* 'î ´' */
  .uil-archive:before { content: '\e835'; } /* 'î µ' */
  .uil-archway:before { content: '\e836'; } /* 'î ¶' */
  .uil-arrow-break:before { content: '\e837'; } /* 'î ·' */
  .uil-arrow-circle-down:before { content: '\e838'; } /* 'î ¸' */
  .uil-arrow-circle-left:before { content: '\e839'; } /* 'î ¹' */
  .uil-arrow-circle-right:before { content: '\e83a'; } /* 'î º' */
  .uil-arrow-circle-up:before { content: '\e83b'; } /* 'î »' */
  .uil-arrow-compress-h:before { content: '\e83c'; } /* 'î ¼' */
  .uil-arrow-down-left:before { content: '\e83d'; } /* 'î ½' */
  .uil-arrow-down-right:before { content: '\e83e'; } /* 'î ¾' */
  .uil-arrow-down:before { content: '\e83f'; } /* 'î ¿' */
  .uil-arrow-from-right:before { content: '\e840'; } /* 'î¡€' */
  .uil-arrow-from-top:before { content: '\e841'; } /* 'î¡' */
  .uil-arrow-growth:before { content: '\e842'; } /* 'î¡‚' */
  .uil-arrow-left:before { content: '\e843'; } /* 'î¡ƒ' */
  .uil-arrow-random:before { content: '\e844'; } /* 'î¡„' */
  .uil-arrow-resize-diagonal:before { content: '\e845'; } /* 'î¡…' */
  .uil-arrow-right:before { content: '\e846'; } /* 'î¡†' */
  .uil-arrow-to-bottom:before { content: '\e847'; } /* 'î¡‡' */
  .uil-arrow-to-right:before { content: '\e848'; } /* 'î¡ˆ' */
  .uil-arrow-up-left:before { content: '\e849'; } /* 'î¡‰' */
  .uil-arrow-up-right:before { content: '\e84a'; } /* 'î¡Š' */
  .uil-arrow-up:before { content: '\e84b'; } /* 'î¡‹' */
  .uil-arrows-h-alt:before { content: '\e84c'; } /* 'î¡Œ' */
  .uil-arrows-h:before { content: '\e84d'; } /* 'î¡' */
  .uil-arrows-left-down:before { content: '\e84e'; } /* 'î¡Ž' */
  .uil-arrows-maximize:before { content: '\e84f'; } /* 'î¡' */
  .uil-arrows-merge:before { content: '\e850'; } /* 'î¡' */
  .uil-arrows-resize-h:before { content: '\e851'; } /* 'î¡‘' */
  .uil-arrows-resize-v:before { content: '\e852'; } /* 'î¡’' */
  .uil-arrows-resize:before { content: '\e853'; } /* 'î¡“' */
  .uil-arrows-right-down:before { content: '\e854'; } /* 'î¡”' */
  .uil-arrows-shrink-h:before { content: '\e855'; } /* 'î¡•' */
  .uil-arrows-shrink-v:before { content: '\e856'; } /* 'î¡–' */
  .uil-arrows-up-right:before { content: '\e857'; } /* 'î¡—' */
  .uil-arrows-v-alt:before { content: '\e858'; } /* 'î¡˜' */
  .uil-arrows-v:before { content: '\e859'; } /* 'î¡™' */
  .uil-assistive-listening-systems:before { content: '\e85a'; } /* 'î¡š' */
  .uil-at:before { content: '\e85b'; } /* 'î¡›' */
  .uil-atm-card:before { content: '\e85c'; } /* 'î¡œ' */
  .uil-atom:before { content: '\e85d'; } /* 'î¡' */
  .uil-auto-flash:before { content: '\e85e'; } /* 'î¡ž' */
  .uil-award-alt:before { content: '\e85f'; } /* 'î¡Ÿ' */
  .uil-award:before { content: '\e860'; } /* 'î¡ ' */
  .uil-baby-carriage:before { content: '\e861'; } /* 'î¡¡' */
  .uil-backpack:before { content: '\e862'; } /* 'î¡¢' */
  .uil-backspace:before { content: '\e863'; } /* 'î¡£' */
  .uil-backward:before { content: '\e864'; } /* 'î¡¤' */
  .uil-bag-alt:before { content: '\e865'; } /* 'î¡¥' */
  .uil-bag-slash:before { content: '\e866'; } /* 'î¡¦' */
  .uil-bag:before { content: '\e867'; } /* 'î¡§' */
  .uil-ball:before { content: '\e868'; } /* 'î¡¨' */
  .uil-ban:before { content: '\e869'; } /* 'î¡©' */
  .uil-bars:before { content: '\e86a'; } /* 'î¡ª' */
  .uil-basketball-hoop:before { content: '\e86b'; } /* 'î¡«' */
  .uil-basketball:before { content: '\e86c'; } /* 'î¡¬' */
  .uil-battery-bolt:before { content: '\e86d'; } /* 'î¡­' */
  .uil-battery-empty:before { content: '\e86e'; } /* 'î¡®' */
  .uil-bed-double:before { content: '\e86f'; } /* 'î¡¯' */
  .uil-bed:before { content: '\e870'; } /* 'î¡°' */
  .uil-behance:before { content: '\e871'; } /* 'î¡±' */
  .uil-bell-school:before { content: '\e872'; } /* 'î¡²' */
  .uil-bell-slash:before { content: '\e873'; } /* 'î¡³' */
  .uil-bell:before { content: '\e874'; } /* 'î¡´' */
  .uil-bill:before { content: '\e875'; } /* 'î¡µ' */
  .uil-bitcoin-circle:before { content: '\e876'; } /* 'î¡¶' */
  .uil-bitcoin:before { content: '\e877'; } /* 'î¡·' */
  .uil-black-berry:before { content: '\e878'; } /* 'î¡¸' */
  .uil-bluetooth-b:before { content: '\e879'; } /* 'î¡¹' */
  .uil-bold:before { content: '\e87a'; } /* 'î¡º' */
  .uil-bolt-alt:before { content: '\e87b'; } /* 'î¡»' */
  .uil-bolt-slash:before { content: '\e87c'; } /* 'î¡¼' */
  .uil-bolt:before { content: '\e87d'; } /* 'î¡½' */
  .uil-book-alt:before { content: '\e87e'; } /* 'î¡¾' */
  .uil-book-medical:before { content: '\e87f'; } /* 'î¡¿' */
  .uil-book-open:before { content: '\e880'; } /* 'î¢€' */
  .uil-book-reader:before { content: '\e881'; } /* 'î¢' */
  .uil-book:before { content: '\e882'; } /* 'î¢‚' */
  .uil-bookmark-full:before { content: '\e883'; } /* 'î¢ƒ' */
  .uil-bookmark:before { content: '\e884'; } /* 'î¢„' */
  .uil-books:before { content: '\e885'; } /* 'î¢…' */
  .uil-boombox:before { content: '\e886'; } /* 'î¢†' */
  .uil-border-alt:before { content: '\e887'; } /* 'î¢‡' */
  .uil-border-bottom:before { content: '\e888'; } /* 'î¢ˆ' */
  .uil-border-clear:before { content: '\e889'; } /* 'î¢‰' */
  .uil-border-horizontal:before { content: '\e88a'; } /* 'î¢Š' */
  .uil-border-inner:before { content: '\e88b'; } /* 'î¢‹' */
  .uil-border-left:before { content: '\e88c'; } /* 'î¢Œ' */
  .uil-border-out:before { content: '\e88d'; } /* 'î¢' */
  .uil-border-right:before { content: '\e88e'; } /* 'î¢Ž' */
  .uil-border-top:before { content: '\e88f'; } /* 'î¢' */
  .uil-border-vertical:before { content: '\e890'; } /* 'î¢' */
  .uil-box:before { content: '\e891'; } /* 'î¢‘' */
  .uil-briefcase-alt:before { content: '\e892'; } /* 'î¢’' */
  .uil-briefcase:before { content: '\e893'; } /* 'î¢“' */
  .uil-bright:before { content: '\e894'; } /* 'î¢”' */
  .uil-brightness-empty:before { content: '\e895'; } /* 'î¢•' */
  .uil-brightness-half:before { content: '\e896'; } /* 'î¢–' */
  .uil-brightness-low:before { content: '\e897'; } /* 'î¢—' */
  .uil-brightness-minus:before { content: '\e898'; } /* 'î¢˜' */
  .uil-brightness-plus:before { content: '\e899'; } /* 'î¢™' */
  .uil-brightness:before { content: '\e89a'; } /* 'î¢š' */
  .uil-bring-bottom:before { content: '\e89b'; } /* 'î¢›' */
  .uil-bring-front:before { content: '\e89c'; } /* 'î¢œ' */
  .uil-brush-alt:before { content: '\e89d'; } /* 'î¢' */
  .uil-bug:before { content: '\e89e'; } /* 'î¢ž' */
  .uil-building:before { content: '\e89f'; } /* 'î¢Ÿ' */
  .uil-bullseye:before { content: '\e8a0'; } /* 'î¢ ' */
  .uil-bus-alt:before { content: '\e8a1'; } /* 'î¢¡' */
  .uil-bus-school:before { content: '\e8a2'; } /* 'î¢¢' */
  .uil-bus:before { content: '\e8a3'; } /* 'î¢£' */
  .uil-calcualtor:before { content: '\e8a4'; } /* 'î¢¤' */
  .uil-calculator-alt:before { content: '\e8a5'; } /* 'î¢¥' */
  .uil-calendar-alt:before { content: '\e8a6'; } /* 'î¢¦' */
  .uil-calendar-slash:before { content: '\e8a7'; } /* 'î¢§' */
  .uil-calender:before { content: '\e8a8'; } /* 'î¢¨' */
  .uil-calling:before { content: '\e8a9'; } /* 'î¢©' */
  .uil-camera-change:before { content: '\e8aa'; } /* 'î¢ª' */
  .uil-camera-plus:before { content: '\e8ab'; } /* 'î¢«' */
  .uil-camera-slash:before { content: '\e8ac'; } /* 'î¢¬' */
  .uil-camera:before { content: '\e8ad'; } /* 'î¢­' */
  .uil-cancel:before { content: '\e8ae'; } /* 'î¢®' */
  .uil-capsule:before { content: '\e8af'; } /* 'î¢¯' */
  .uil-capture:before { content: '\e8b0'; } /* 'î¢°' */
  .uil-car-sideview:before { content: '\e8b1'; } /* 'î¢±' */
  .uil-car-slash:before { content: '\e8b2'; } /* 'î¢²' */
  .uil-car-wash:before { content: '\e8b3'; } /* 'î¢³' */
  .uil-car:before { content: '\e8b4'; } /* 'î¢´' */
  .uil-card-atm:before { content: '\e8b5'; } /* 'î¢µ' */
  .uil-caret-right:before { content: '\e8b6'; } /* 'î¢¶' */
  .uil-cart:before { content: '\e8b7'; } /* 'î¢·' */
  .uil-cell:before { content: '\e8b8'; } /* 'î¢¸' */
  .uil-celsius:before { content: '\e8b9'; } /* 'î¢¹' */
  .uil-chart-bar-alt:before { content: '\e8ba'; } /* 'î¢º' */
  .uil-chart-bar:before { content: '\e8bb'; } /* 'î¢»' */
  .uil-chart-down:before { content: '\e8bc'; } /* 'î¢¼' */
  .uil-chart-growth-alt:before { content: '\e8bd'; } /* 'î¢½' */
  .uil-chart-growth:before { content: '\e8be'; } /* 'î¢¾' */
  .uil-chart-line:before { content: '\e8bf'; } /* 'î¢¿' */
  .uil-chart-pie-alt:before { content: '\e8c0'; } /* 'î£€' */
  .uil-chart-pie:before { content: '\e8c1'; } /* 'î£' */
  .uil-chart:before { content: '\e8c2'; } /* 'î£‚' */
  .uil-chat-bubble-user:before { content: '\e8c3'; } /* 'î£ƒ' */
  .uil-chat-info:before { content: '\e8c4'; } /* 'î£„' */
  .uil-chat:before { content: '\e8c5'; } /* 'î£…' */
  .uil-check-circle:before { content: '\e8c6'; } /* 'î£†' */
  .uil-check-square:before { content: '\e8c7'; } /* 'î£‡' */
  .uil-check:before { content: '\e8c8'; } /* 'î£ˆ' */
  .uil-circle-layer:before { content: '\e8c9'; } /* 'î£‰' */
  .uil-circle:before { content: '\e8ca'; } /* 'î£Š' */
  .uil-circuit:before { content: '\e8cb'; } /* 'î£‹' */
  .uil-clapper-board:before { content: '\e8cc'; } /* 'î£Œ' */
  .uil-clipboard-alt:before { content: '\e8cd'; } /* 'î£' */
  .uil-clipboard-blank:before { content: '\e8ce'; } /* 'î£Ž' */
  .uil-clipboard-notes:before { content: '\e8cf'; } /* 'î£' */
  .uil-clipboard:before { content: '\e8d0'; } /* 'î£' */
  .uil-clock-eight:before { content: '\e8d1'; } /* 'î£‘' */
  .uil-clock-five:before { content: '\e8d2'; } /* 'î£’' */
  .uil-clock-nine:before { content: '\e8d3'; } /* 'î£“' */
  .uil-clock-seven:before { content: '\e8d4'; } /* 'î£”' */
  .uil-clock-ten:before { content: '\e8d5'; } /* 'î£•' */
  .uil-clock-three:before { content: '\e8d6'; } /* 'î£–' */
  .uil-clock-two:before { content: '\e8d7'; } /* 'î£—' */
  .uil-clock:before { content: '\e8d8'; } /* 'î£˜' */
  .uil-closed-captioning:before { content: '\e8d9'; } /* 'î£™' */
  .uil-cloud-block:before { content: '\e8da'; } /* 'î£š' */
  .uil-cloud-bookmark:before { content: '\e8db'; } /* 'î£›' */
  .uil-cloud-check:before { content: '\e8dc'; } /* 'î£œ' */
  .uil-cloud-computing:before { content: '\e8dd'; } /* 'î£' */
  .uil-cloud-data-connection:before { content: '\e8de'; } /* 'î£ž' */
  .uil-cloud-database-tree:before { content: '\e8df'; } /* 'î£Ÿ' */
  .uil-cloud-download:before { content: '\e8e0'; } /* 'î£ ' */
  .uil-cloud-drizzle:before { content: '\e8e1'; } /* 'î£¡' */
  .uil-cloud-exclamation:before { content: '\e8e2'; } /* 'î£¢' */
  .uil-cloud-hail:before { content: '\e8e3'; } /* 'î££' */
  .uil-cloud-heart:before { content: '\e8e4'; } /* 'î£¤' */
  .uil-cloud-info:before { content: '\e8e5'; } /* 'î£¥' */
  .uil-cloud-lock:before { content: '\e8e6'; } /* 'î£¦' */
  .uil-cloud-meatball:before { content: '\e8e7'; } /* 'î£§' */
  .uil-cloud-moon-hail:before { content: '\e8e8'; } /* 'î£¨' */
  .uil-cloud-moon-meatball:before { content: '\e8e9'; } /* 'î£©' */
  .uil-cloud-moon-rain:before { content: '\e8ea'; } /* 'î£ª' */
  .uil-cloud-moon-showers:before { content: '\e8eb'; } /* 'î£«' */
  .uil-cloud-moon:before { content: '\e8ec'; } /* 'î£¬' */
  .uil-cloud-question:before { content: '\e8ed'; } /* 'î£­' */
  .uil-cloud-rain-sun:before { content: '\e8ee'; } /* 'î£®' */
  .uil-cloud-rain:before { content: '\e8ef'; } /* 'î£¯' */
  .uil-cloud-redo:before { content: '\e8f0'; } /* 'î£°' */
  .uil-cloud-set:before { content: '\e8f1'; } /* 'î£±' */
  .uil-cloud-share:before { content: '\e8f2'; } /* 'î£²' */
  .uil-cloud-shield:before { content: '\e8f3'; } /* 'î£³' */
  .uil-cloud-showers-alt:before { content: '\e8f4'; } /* 'î£´' */
  .uil-cloud-showers-heavy:before { content: '\e8f5'; } /* 'î£µ' */
  .uil-cloud-showers:before { content: '\e8f6'; } /* 'î£¶' */
  .uil-cloud-slash:before { content: '\e8f7'; } /* 'î£·' */
  .uil-cloud-sun-hail:before { content: '\e8f8'; } /* 'î£¸' */
  .uil-cloud-sun-meatball:before { content: '\e8f9'; } /* 'î£¹' */
  .uil-cloud-sun-rain-alt:before { content: '\e8fa'; } /* 'î£º' */
  .uil-cloud-sun-rain:before { content: '\e8fb'; } /* 'î£»' */
  .uil-cloud-sun-tear:before { content: '\e8fc'; } /* 'î£¼' */
  .uil-cloud-sun:before { content: '\e8fd'; } /* 'î£½' */
  .uil-cloud-times:before { content: '\e8fe'; } /* 'î£¾' */
  .uil-cloud-unlock:before { content: '\e8ff'; } /* 'î£¿' */
  .uil-cloud-upload:before { content: '\e900'; } /* 'î¤€' */
  .uil-cloud-wifi:before { content: '\e901'; } /* 'î¤' */
  .uil-cloud-wind:before { content: '\e902'; } /* 'î¤‚' */
  .uil-cloud:before { content: '\e903'; } /* 'î¤ƒ' */
  .uil-clouds:before { content: '\e904'; } /* 'î¤„' */
  .uil-club:before { content: '\e905'; } /* 'î¤…' */
  .uil-code:before { content: '\e906'; } /* 'î¤†' */
  .uil-coffee:before { content: '\e907'; } /* 'î¤‡' */
  .uil-cog:before { content: '\e908'; } /* 'î¤ˆ' */
  .uil-coins:before { content: '\e909'; } /* 'î¤‰' */
  .uil-columns:before { content: '\e90a'; } /* 'î¤Š' */
  .uil-comment-alt-block:before { content: '\e90b'; } /* 'î¤‹' */
  .uil-comment-alt-chart-lines:before { content: '\e90c'; } /* 'î¤Œ' */
  .uil-comment-alt-check:before { content: '\e90d'; } /* 'î¤' */
  .uil-comment-alt-dots:before { content: '\e90e'; } /* 'î¤Ž' */
  .uil-comment-alt-download:before { content: '\e90f'; } /* 'î¤' */
  .uil-comment-alt-edit:before { content: '\e910'; } /* 'î¤' */
  .uil-comment-alt-exclamation:before { content: '\e911'; } /* 'î¤‘' */
  .uil-comment-alt-heart:before { content: '\e912'; } /* 'î¤’' */
  .uil-comment-alt-image:before { content: '\e913'; } /* 'î¤“' */
  .uil-comment-alt-info:before { content: '\e914'; } /* 'î¤”' */
  .uil-comment-alt-lines:before { content: '\e915'; } /* 'î¤•' */
  .uil-comment-alt-lock:before { content: '\e916'; } /* 'î¤–' */
  .uil-comment-alt-medical:before { content: '\e917'; } /* 'î¤—' */
  .uil-comment-alt-message:before { content: '\e918'; } /* 'î¤˜' */
  .uil-comment-alt-notes:before { content: '\e919'; } /* 'î¤™' */
  .uil-comment-alt-plus:before { content: '\e91a'; } /* 'î¤š' */
  .uil-comment-alt-question:before { content: '\e91b'; } /* 'î¤›' */
  .uil-comment-alt-redo:before { content: '\e91c'; } /* 'î¤œ' */
  .uil-comment-alt-search:before { content: '\e91d'; } /* 'î¤' */
  .uil-comment-alt-share:before { content: '\e91e'; } /* 'î¤ž' */
  .uil-comment-alt-shield:before { content: '\e91f'; } /* 'î¤Ÿ' */
  .uil-comment-alt-slash:before { content: '\e920'; } /* 'î¤ ' */
  .uil-comment-alt-upload:before { content: '\e921'; } /* 'î¤¡' */
  .uil-comment-alt-verify:before { content: '\e922'; } /* 'î¤¢' */
  .uil-comment-alt:before { content: '\e923'; } /* 'î¤£' */
  .uil-comment-block:before { content: '\e924'; } /* 'î¤¤' */
  .uil-comment-chart-line:before { content: '\e925'; } /* 'î¤¥' */
  .uil-comment-check:before { content: '\e926'; } /* 'î¤¦' */
  .uil-comment-dots:before { content: '\e927'; } /* 'î¤§' */
  .uil-comment-download:before { content: '\e928'; } /* 'î¤¨' */
  .uil-comment-edit:before { content: '\e929'; } /* 'î¤©' */
  .uil-comment-exclamation:before { content: '\e92a'; } /* 'î¤ª' */
  .uil-comment-heart:before { content: '\e92b'; } /* 'î¤«' */
  .uil-comment-image:before { content: '\e92c'; } /* 'î¤¬' */
  .uil-comment-info-alt:before { content: '\e92d'; } /* 'î¤­' */
  .uil-comment-info:before { content: '\e92e'; } /* 'î¤®' */
  .uil-comment-lines:before { content: '\e92f'; } /* 'î¤¯' */
  .uil-comment-lock:before { content: '\e930'; } /* 'î¤°' */
  .uil-comment-medical:before { content: '\e931'; } /* 'î¤±' */
  .uil-comment-message:before { content: '\e932'; } /* 'î¤²' */
  .uil-comment-notes:before { content: '\e933'; } /* 'î¤³' */
  .uil-comment-plus:before { content: '\e934'; } /* 'î¤´' */
  .uil-comment-question:before { content: '\e935'; } /* 'î¤µ' */
  .uil-comment-redo:before { content: '\e936'; } /* 'î¤¶' */
  .uil-comment-search:before { content: '\e937'; } /* 'î¤·' */
  .uil-comment-share:before { content: '\e938'; } /* 'î¤¸' */
  .uil-comment-shield:before { content: '\e939'; } /* 'î¤¹' */
  .uil-comment-slash:before { content: '\e93a'; } /* 'î¤º' */
  .uil-comment-upload:before { content: '\e93b'; } /* 'î¤»' */
  .uil-comment-verify:before { content: '\e93c'; } /* 'î¤¼' */
  .uil-comment:before { content: '\e93d'; } /* 'î¤½' */
  .uil-comments-alt:before { content: '\e93e'; } /* 'î¤¾' */
  .uil-comments:before { content: '\e93f'; } /* 'î¤¿' */
  .uil-commnet-alt-slash:before { content: '\e940'; } /* 'î¥€' */
  .uil-compact-disc:before { content: '\e941'; } /* 'î¥' */
  .uil-compass:before { content: '\e942'; } /* 'î¥‚' */
  .uil-compress-alt-left:before { content: '\e943'; } /* 'î¥ƒ' */
  .uil-compress-alt:before { content: '\e944'; } /* 'î¥„' */
  .uil-compress-arrows:before { content: '\e945'; } /* 'î¥…' */
  .uil-compress-lines:before { content: '\e946'; } /* 'î¥†' */
  .uil-compress-point:before { content: '\e947'; } /* 'î¥‡' */
  .uil-compress-v:before { content: '\e948'; } /* 'î¥ˆ' */
  .uil-compress:before { content: '\e949'; } /* 'î¥‰' */
  .uil-computer-mouse:before { content: '\e94a'; } /* 'î¥Š' */
  .uil-confused:before { content: '\e94b'; } /* 'î¥‹' */
  .uil-constructor:before { content: '\e94c'; } /* 'î¥Œ' */
  .uil-copy-alt:before { content: '\e94d'; } /* 'î¥' */
  .uil-copy-landscape:before { content: '\e94e'; } /* 'î¥Ž' */
  .uil-copy:before { content: '\e94f'; } /* 'î¥' */
  .uil-copyright:before { content: '\e950'; } /* 'î¥' */
  .uil-corner-down-left:before { content: '\e951'; } /* 'î¥‘' */
  .uil-corner-down-right-alt:before { content: '\e952'; } /* 'î¥’' */
  .uil-corner-down-right:before { content: '\e953'; } /* 'î¥“' */
  .uil-corner-left-down:before { content: '\e954'; } /* 'î¥”' */
  .uil-corner-right-down:before { content: '\e955'; } /* 'î¥•' */
  .uil-corner-up-left-alt:before { content: '\e956'; } /* 'î¥–' */
  .uil-corner-up-left:before { content: '\e957'; } /* 'î¥—' */
  .uil-corner-up-right-alt:before { content: '\e958'; } /* 'î¥˜' */
  .uil-corner-up-right:before { content: '\e959'; } /* 'î¥™' */
  .uil-creative-commons-pd-alt:before { content: '\e95a'; } /* 'î¥š' */
  .uil-creative-commons-pd:before { content: '\e95b'; } /* 'î¥›' */
  .uil-crockery:before { content: '\e95c'; } /* 'î¥œ' */
  .uil-crop-alt-rotate-left:before { content: '\e95d'; } /* 'î¥' */
  .uil-crop-alt-rotate-right:before { content: '\e95e'; } /* 'î¥ž' */
  .uil-crop-alt:before { content: '\e95f'; } /* 'î¥Ÿ' */
  .uil-crosshair-alt:before { content: '\e960'; } /* 'î¥ ' */
  .uil-crosshair:before { content: '\e961'; } /* 'î¥¡' */
  .uil-crosshairs:before { content: '\e962'; } /* 'î¥¢' */
  .uil-cube:before { content: '\e963'; } /* 'î¥£' */
  .uil-data-sharing:before { content: '\e964'; } /* 'î¥¤' */
  .uil-database-alt:before { content: '\e965'; } /* 'î¥¥' */
  .uil-database:before { content: '\e966'; } /* 'î¥¦' */
  .uil-desert:before { content: '\e967'; } /* 'î¥§' */
  .uil-desktop-alt-slash:before { content: '\e968'; } /* 'î¥¨' */
  .uil-desktop-alt:before { content: '\e969'; } /* 'î¥©' */
  .uil-desktop-cloud-alt:before { content: '\e96a'; } /* 'î¥ª' */
  .uil-desktop-slash:before { content: '\e96b'; } /* 'î¥«' */
  .uil-desktop:before { content: '\e96c'; } /* 'î¥¬' */
  .uil-dialpad-alt:before { content: '\e96d'; } /* 'î¥­' */
  .uil-dialpad:before { content: '\e96e'; } /* 'î¥®' */
  .uil-diamond:before { content: '\e96f'; } /* 'î¥¯' */
  .uil-diary-alt:before { content: '\e970'; } /* 'î¥°' */
  .uil-diary:before { content: '\e971'; } /* 'î¥±' */
  .uil-dice-five:before { content: '\e972'; } /* 'î¥²' */
  .uil-dice-four:before { content: '\e973'; } /* 'î¥³' */
  .uil-dice-one:before { content: '\e974'; } /* 'î¥´' */
  .uil-dice-six:before { content: '\e975'; } /* 'î¥µ' */
  .uil-dice-three:before { content: '\e976'; } /* 'î¥¶' */
  .uil-dice-two:before { content: '\e977'; } /* 'î¥·' */
  .uil-direction:before { content: '\e978'; } /* 'î¥¸' */
  .uil-directions:before { content: '\e979'; } /* 'î¥¹' */
  .uil-dizzy-meh:before { content: '\e97a'; } /* 'î¥º' */
  .uil-dna:before { content: '\e97b'; } /* 'î¥»' */
  .uil-document-layout-center:before { content: '\e97c'; } /* 'î¥¼' */
  .uil-document-layout-left:before { content: '\e97d'; } /* 'î¥½' */
  .uil-document-layout-right:before { content: '\e97e'; } /* 'î¥¾' */
  .uil-document:before { content: '\e97f'; } /* 'î¥¿' */
  .uil-dollar-alt:before { content: '\e980'; } /* 'î¦€' */
  .uil-dollar-sign-alt:before { content: '\e981'; } /* 'î¦' */
  .uil-dollar-sign:before { content: '\e982'; } /* 'î¦‚' */
  .uil-down-arrow:before { content: '\e983'; } /* 'î¦ƒ' */
  .uil-download-alt:before { content: '\e984'; } /* 'î¦„' */
  .uil-dribbble:before { content: '\e985'; } /* 'î¦…' */
  .uil-drill:before { content: '\e986'; } /* 'î¦†' */
  .uil-dropbox:before { content: '\e987'; } /* 'î¦‡' */
  .uil-dumbbell:before { content: '\e988'; } /* 'î¦ˆ' */
  .uil-edit-alt:before { content: '\e98a'; } /* 'î¦Š' */
  .uil-edit:before { content: '\e98b'; } /* 'î¦‹' */
  .uil-ellipsis-h:before { content: '\e98c'; } /* 'î¦Œ' */
  .uil-ellipsis-v:before { content: '\e98d'; } /* 'î¦' */
  .uil-emoji:before { content: '\e98e'; } /* 'î¦Ž' */
  .uil-enter:before { content: '\e98f'; } /* 'î¦' */
  .uil-entry:before { content: '\e990'; } /* 'î¦' */
  .uil-envelope-add:before { content: '\e991'; } /* 'î¦‘' */
  .uil-envelope-alt:before { content: '\e992'; } /* 'î¦’' */
  .uil-envelope-block:before { content: '\e993'; } /* 'î¦“' */
  .uil-envelope-bookmark:before { content: '\e994'; } /* 'î¦”' */
  .uil-envelope-check:before { content: '\e995'; } /* 'î¦•' */
  .uil-envelope-download-alt:before { content: '\e996'; } /* 'î¦–' */
  .uil-envelope-download:before { content: '\e997'; } /* 'î¦—' */
  .uil-envelope-edit:before { content: '\e998'; } /* 'î¦˜' */
  .uil-envelope-exclamation:before { content: '\e999'; } /* 'î¦™' */
  .uil-envelope-heart:before { content: '\e99a'; } /* 'î¦š' */
  .uil-envelope-info:before { content: '\e99b'; } /* 'î¦›' */
  .uil-envelope-lock:before { content: '\e99c'; } /* 'î¦œ' */
  .uil-envelope-minus:before { content: '\e99d'; } /* 'î¦' */
  .uil-envelope-open:before { content: '\e99e'; } /* 'î¦ž' */
  .uil-envelope-question:before { content: '\e99f'; } /* 'î¦Ÿ' */
  .uil-envelope-receive:before { content: '\e9a0'; } /* 'î¦ ' */
  .uil-envelope-redo:before { content: '\e9a1'; } /* 'î¦¡' */
  .uil-envelope-search:before { content: '\e9a2'; } /* 'î¦¢' */
  .uil-envelope-send:before { content: '\e9a3'; } /* 'î¦£' */
  .uil-envelope-share:before { content: '\e9a4'; } /* 'î¦¤' */
  .uil-envelope-shield:before { content: '\e9a5'; } /* 'î¦¥' */
  .uil-envelope-star:before { content: '\e9a6'; } /* 'î¦¦' */
  .uil-envelope-times:before { content: '\e9a7'; } /* 'î¦§' */
  .uil-envelope-upload-alt:before { content: '\e9a8'; } /* 'î¦¨' */
  .uil-envelope-upload:before { content: '\e9a9'; } /* 'î¦©' */
  .uil-envelope:before { content: '\e9aa'; } /* 'î¦ª' */
  .uil-envelopes:before { content: '\e9ab'; } /* 'î¦«' */
  .uil-equal-circle:before { content: '\e9ac'; } /* 'î¦¬' */
  .uil-euro-circle:before { content: '\e9ad'; } /* 'î¦­' */
  .uil-euro:before { content: '\e9ae'; } /* 'î¦®' */
  .uil-exchange-alt:before { content: '\e9af'; } /* 'î¦¯' */
  .uil-exchange:before { content: '\e9b0'; } /* 'î¦°' */
  .uil-exclamation-circle:before { content: '\e9b1'; } /* 'î¦±' */
  .uil-exclamation-octagon:before { content: '\e9b2'; } /* 'î¦²' */
  .uil-exclamation-triangle:before { content: '\e9b3'; } /* 'î¦³' */
  .uil-exclude:before { content: '\e9b4'; } /* 'î¦´' */
  .uil-exit:before { content: '\e9b5'; } /* 'î¦µ' */
  .uil-expand-alt:before { content: '\e9b6'; } /* 'î¦¶' */
  .uil-expand-arrows-alt:before { content: '\e9b7'; } /* 'î¦·' */
  .uil-expand-arrows:before { content: '\e9b8'; } /* 'î¦¸' */
  .uil-expand-from-corner:before { content: '\e9b9'; } /* 'î¦¹' */
  .uil-expand-left:before { content: '\e9ba'; } /* 'î¦º' */
  .uil-expand-right:before { content: '\e9bb'; } /* 'î¦»' */
  .uil-export:before { content: '\e9bc'; } /* 'î¦¼' */
  .uil-exposure-alt:before { content: '\e9bd'; } /* 'î¦½' */
  .uil-exposure-increase:before { content: '\e9be'; } /* 'î¦¾' */
  .uil-external-link-alt:before { content: '\e9bf'; } /* 'î¦¿' */
  .uil-eye-slash:before { content: '\e9c0'; } /* 'î§€' */
  .uil-eye:before { content: '\e9c1'; } /* 'î§' */
  .uil-facebook-f:before { content: '\e9c2'; } /* 'î§‚' */
  .uil-facebook-messenger:before { content: '\e9c3'; } /* 'î§ƒ' */
  .uil-facebook:before { content: '\e9c4'; } /* 'î§„' */
  .uil-fahrenheit:before { content: '\e9c5'; } /* 'î§…' */
  .uil-fast-mail-alt:before { content: '\e9c6'; } /* 'î§†' */
  .uil-fast-mail:before { content: '\e9c7'; } /* 'î§‡' */
  .uil-favorite:before { content: '\e9c8'; } /* 'î§ˆ' */
  .uil-feedback:before { content: '\e9c9'; } /* 'î§‰' */
  .uil-file-alt:before { content: '\e9ca'; } /* 'î§Š' */
  .uil-file-blank:before { content: '\e9cb'; } /* 'î§‹' */
  .uil-file-block-alt:before { content: '\e9cc'; } /* 'î§Œ' */
  .uil-file-bookmark-alt:before { content: '\e9cd'; } /* 'î§' */
  .uil-file-check-alt:before { content: '\e9ce'; } /* 'î§Ž' */
  .uil-file-check:before { content: '\e9cf'; } /* 'î§' */
  .uil-file-contract-dollar:before { content: '\e9d0'; } /* 'î§' */
  .uil-file-copy-alt:before { content: '\e9d1'; } /* 'î§‘' */
  .uil-file-download-alt:before { content: '\e9d2'; } /* 'î§’' */
  .uil-file-download:before { content: '\e9d3'; } /* 'î§“' */
  .uil-file-edit-alt:before { content: '\e9d4'; } /* 'î§”' */
  .uil-file-exclamation-alt:before { content: '\e9d5'; } /* 'î§•' */
  .uil-file-exclamation:before { content: '\e9d6'; } /* 'î§–' */
  .uil-file-heart:before { content: '\e9d7'; } /* 'î§—' */
  .uil-file-info-alt:before { content: '\e9d8'; } /* 'î§˜' */
  .uil-file-landscape-alt:before { content: '\e9d9'; } /* 'î§™' */
  .uil-file-landscape:before { content: '\e9da'; } /* 'î§š' */
  .uil-file-lanscape-slash:before { content: '\e9db'; } /* 'î§›' */
  .uil-file-lock-alt:before { content: '\e9dc'; } /* 'î§œ' */
  .uil-file-medical-alt:before { content: '\e9dd'; } /* 'î§' */
  .uil-file-medical:before { content: '\e9de'; } /* 'î§ž' */
  .uil-file-minus-alt:before { content: '\e9df'; } /* 'î§Ÿ' */
  .uil-file-minus:before { content: '\e9e0'; } /* 'î§ ' */
  .uil-file-network:before { content: '\e9e1'; } /* 'î§¡' */
  .uil-file-plus-alt:before { content: '\e9e2'; } /* 'î§¢' */
  .uil-file-plus:before { content: '\e9e3'; } /* 'î§£' */
  .uil-file-question-alt:before { content: '\e9e4'; } /* 'î§¤' */
  .uil-file-question:before { content: '\e9e5'; } /* 'î§¥' */
  .uil-file-redo-alt:before { content: '\e9e6'; } /* 'î§¦' */
  .uil-file-search-alt:before { content: '\e9e7'; } /* 'î§§' */
  .uil-file-share-alt:before { content: '\e9e8'; } /* 'î§¨' */
  .uil-file-shield-alt:before { content: '\e9e9'; } /* 'î§©' */
  .uil-file-slash:before { content: '\e9ea'; } /* 'î§ª' */
  .uil-file-times-alt:before { content: '\e9eb'; } /* 'î§«' */
  .uil-file-times:before { content: '\e9ec'; } /* 'î§¬' */
  .uil-file-upload-alt:before { content: '\e9ed'; } /* 'î§­' */
  .uil-file-upload:before { content: '\e9ee'; } /* 'î§®' */
  .uil-file:before { content: '\e9ef'; } /* 'î§¯' */
  .uil-files-landscapes-alt:before { content: '\e9f0'; } /* 'î§°' */
  .uil-files-landscapes:before { content: '\e9f1'; } /* 'î§±' */
  .uil-film:before { content: '\e9f2'; } /* 'î§²' */
  .uil-filter-slash:before { content: '\e9f3'; } /* 'î§³' */
  .uil-filter:before { content: '\e9f4'; } /* 'î§´' */
  .uil-flask-potion:before { content: '\e9f5'; } /* 'î§µ' */
  .uil-flask:before { content: '\e9f6'; } /* 'î§¶' */
  .uil-flip-h-alt:before { content: '\e9f7'; } /* 'î§·' */
  .uil-flip-h:before { content: '\e9f8'; } /* 'î§¸' */
  .uil-flip-v-alt:before { content: '\e9f9'; } /* 'î§¹' */
  .uil-flip-v:before { content: '\e9fa'; } /* 'î§º' */
  .uil-flower:before { content: '\e9fb'; } /* 'î§»' */
  .uil-focus-add:before { content: '\e9fc'; } /* 'î§¼' */
  .uil-focus-target:before { content: '\e9fd'; } /* 'î§½' */
  .uil-focus:before { content: '\e9fe'; } /* 'î§¾' */
  .uil-folder-check:before { content: '\e9ff'; } /* 'î§¿' */
  .uil-folder-download:before { content: '\ea00'; } /* 'î¨€' */
  .uil-folder-exclamation:before { content: '\ea01'; } /* 'î¨' */
  .uil-folder-heart:before { content: '\ea02'; } /* 'î¨‚' */
  .uil-folder-info:before { content: '\ea03'; } /* 'î¨ƒ' */
  .uil-folder-lock:before { content: '\ea04'; } /* 'î¨„' */
  .uil-folder-medical:before { content: '\ea05'; } /* 'î¨…' */
  .uil-folder-minus:before { content: '\ea06'; } /* 'î¨†' */
  .uil-folder-network:before { content: '\ea07'; } /* 'î¨‡' */
  .uil-folder-plus:before { content: '\ea08'; } /* 'î¨ˆ' */
  .uil-folder-question:before { content: '\ea09'; } /* 'î¨‰' */
  .uil-folder-slash:before { content: '\ea0a'; } /* 'î¨Š' */
  .uil-folder-times:before { content: '\ea0b'; } /* 'î¨‹' */
  .uil-folder-upload:before { content: '\ea0c'; } /* 'î¨Œ' */
  .uil-folder:before { content: '\ea0d'; } /* 'î¨' */
  .uil-food:before { content: '\ea0e'; } /* 'î¨Ž' */
  .uil-football-american:before { content: '\ea0f'; } /* 'î¨' */
  .uil-football-ball:before { content: '\ea10'; } /* 'î¨' */
  .uil-football:before { content: '\ea11'; } /* 'î¨‘' */
  .uil-forecastcloud-moon-tear:before { content: '\ea12'; } /* 'î¨’' */
  .uil-forwaded-call:before { content: '\ea13'; } /* 'î¨“' */
  .uil-forward:before { content: '\ea14'; } /* 'î¨”' */
  .uil-frown:before { content: '\ea15'; } /* 'î¨•' */
  .uil-game-structure:before { content: '\ea16'; } /* 'î¨–' */
  .uil-game:before { content: '\ea17'; } /* 'î¨—' */
  .uil-gift:before { content: '\ea18'; } /* 'î¨˜' */
  .uil-glass-martini-alt-slash:before { content: '\ea19'; } /* 'î¨™' */
  .uil-glass-martini-alt:before { content: '\ea1a'; } /* 'î¨š' */
  .uil-glass-martini:before { content: '\ea1b'; } /* 'î¨›' */
  .uil-glass-tea:before { content: '\ea1c'; } /* 'î¨œ' */
  .uil-glass:before { content: '\ea1d'; } /* 'î¨' */
  .uil-globe:before { content: '\ea1e'; } /* 'î¨ž' */
  .uil-gold:before { content: '\ea1f'; } /* 'î¨Ÿ' */
  .uil-google-drive:before { content: '\ea20'; } /* 'î¨ ' */
  .uil-graduation-hat:before { content: '\ea21'; } /* 'î¨¡' */
  .uil-graph-bar:before { content: '\ea22'; } /* 'î¨¢' */
  .uil-grid:before { content: '\ea23'; } /* 'î¨£' */
  .uil-grids:before { content: '\ea24'; } /* 'î¨¤' */
  .uil-grin-tongue-wink-alt:before { content: '\ea25'; } /* 'î¨¥' */
  .uil-grin-tongue-wink:before { content: '\ea26'; } /* 'î¨¦' */
  .uil-grin:before { content: '\ea27'; } /* 'î¨§' */
  .uil-grip-horizontal-line:before { content: '\ea28'; } /* 'î¨¨' */
  .uil-hdd:before { content: '\ea29'; } /* 'î¨©' */
  .uil-headphones-alt:before { content: '\ea2a'; } /* 'î¨ª' */
  .uil-headphones:before { content: '\ea2b'; } /* 'î¨«' */
  .uil-heart-alt:before { content: '\ea2c'; } /* 'î¨¬' */
  .uil-heart-medical:before { content: '\ea2d'; } /* 'î¨­' */
  .uil-heart-rate:before { content: '\ea2e'; } /* 'î¨®' */
  .uil-heart-sign:before { content: '\ea2f'; } /* 'î¨¯' */
  .uil-heart:before { content: '\ea30'; } /* 'î¨°' */
  .uil-heartbeat:before { content: '\ea31'; } /* 'î¨±' */
  .uil-history-alt:before { content: '\ea32'; } /* 'î¨²' */
  .uil-history:before { content: '\ea33'; } /* 'î¨³' */
  .uil-home-alt:before { content: '\ea34'; } /* 'î¨´' */
  .uil-home:before { content: '\ea35'; } /* 'î¨µ' */
  .uil-horizontal-align-center:before { content: '\ea36'; } /* 'î¨¶' */
  .uil-horizontal-align-left:before { content: '\ea37'; } /* 'î¨·' */
  .uil-horizontal-align-right:before { content: '\ea38'; } /* 'î¨¸' */
  .uil-horizontal-distribution-center:before { content: '\ea39'; } /* 'î¨¹' */
  .uil-horizontal-distribution-left:before { content: '\ea3a'; } /* 'î¨º' */
  .uil-horizontal-distribution-right:before { content: '\ea3b'; } /* 'î¨»' */
  .uil-hunting:before { content: '\ea3c'; } /* 'î¨¼' */
  .uil-image-alt-slash:before { content: '\ea3d'; } /* 'î¨½' */
  .uil-image-block:before { content: '\ea3e'; } /* 'î¨¾' */
  .uil-image-broken:before { content: '\ea3f'; } /* 'î¨¿' */
  .uil-image-check:before { content: '\ea40'; } /* 'î©€' */
  .uil-image-download:before { content: '\ea41'; } /* 'î©' */
  .uil-image-edit:before { content: '\ea42'; } /* 'î©‚' */
  .uil-image-lock:before { content: '\ea43'; } /* 'î©ƒ' */
  .uil-image-minus:before { content: '\ea44'; } /* 'î©„' */
  .uil-image-plus:before { content: '\ea45'; } /* 'î©…' */
  .uil-image-question:before { content: '\ea46'; } /* 'î©†' */
  .uil-image-redo:before { content: '\ea47'; } /* 'î©‡' */
  .uil-image-resize-landscape:before { content: '\ea48'; } /* 'î©ˆ' */
  .uil-image-resize-square:before { content: '\ea49'; } /* 'î©‰' */
  .uil-image-search:before { content: '\ea4a'; } /* 'î©Š' */
  .uil-image-share:before { content: '\ea4b'; } /* 'î©‹' */
  .uil-image-shield:before { content: '\ea4c'; } /* 'î©Œ' */
  .uil-image-slash:before { content: '\ea4d'; } /* 'î©' */
  .uil-image-times:before { content: '\ea4e'; } /* 'î©Ž' */
  .uil-image-upload:before { content: '\ea4f'; } /* 'î©' */
  .uil-image-v:before { content: '\ea50'; } /* 'î©' */
  .uil-image:before { content: '\ea51'; } /* 'î©‘' */
  .uil-images:before { content: '\ea52'; } /* 'î©’' */
  .uil-incoming-call:before { content: '\ea53'; } /* 'î©“' */
  .uil-info-circle:before { content: '\ea54'; } /* 'î©”' */
  .uil-instagram-alt:before { content: '\ea55'; } /* 'î©•' */
  .uil-instagram:before { content: '\ea56'; } /* 'î©–' */
  .uil-intercom:before { content: '\ea57'; } /* 'î©—' */
  .uil-invoice:before { content: '\ea58'; } /* 'î©˜' */
  .uil-italic:before { content: '\ea59'; } /* 'î©™' */
  .uil-jackhammer:before { content: '\ea5a'; } /* 'î©š' */
  .uil-kayak:before { content: '\ea5b'; } /* 'î©›' */
  .uil-key-skeleton-alt:before { content: '\ea5c'; } /* 'î©œ' */
  .uil-key-skeleton:before { content: '\ea5d'; } /* 'î©' */
  .uil-keyboard-alt:before { content: '\ea5e'; } /* 'î©ž' */
  .uil-keyboard-hide:before { content: '\ea5f'; } /* 'î©Ÿ' */
  .uil-keyboard-show:before { content: '\ea60'; } /* 'î© ' */
  .uil-keyboard:before { content: '\ea61'; } /* 'î©¡' */
  .uil-keyhole-circle:before { content: '\ea62'; } /* 'î©¢' */
  .uil-keyhole-square-full:before { content: '\ea63'; } /* 'î©£' */
  .uil-keyhole-square:before { content: '\ea64'; } /* 'î©¤' */
  .uil-kid:before { content: '\ea65'; } /* 'î©¥' */
  .uil-label-alt:before { content: '\ea66'; } /* 'î©¦' */
  .uil-label:before { content: '\ea67'; } /* 'î©§' */
  .uil-lamp:before { content: '\ea68'; } /* 'î©¨' */
  .uil-laptop-cloud:before { content: '\ea69'; } /* 'î©©' */
  .uil-laptop:before { content: '\ea6a'; } /* 'î©ª' */
  .uil-laughing:before { content: '\ea6b'; } /* 'î©«' */
  .uil-layer-group-slash:before { content: '\ea6c'; } /* 'î©¬' */
  .uil-layer-group:before { content: '\ea6d'; } /* 'î©­' */
  .uil-layers-alt:before { content: '\ea6e'; } /* 'î©®' */
  .uil-layers-slash:before { content: '\ea6f'; } /* 'î©¯' */
  .uil-layers:before { content: '\ea70'; } /* 'î©°' */
  .uil-left-arrow-from-left:before { content: '\ea71'; } /* 'î©±' */
  .uil-left-arrow-to-left:before { content: '\ea72'; } /* 'î©²' */
  .uil-left-indent-alt:before { content: '\ea73'; } /* 'î©³' */
  .uil-left-indent:before { content: '\ea74'; } /* 'î©´' */
  .uil-left-to-right-text-direction:before { content: '\ea75'; } /* 'î©µ' */
  .uil-life-ring:before { content: '\ea76'; } /* 'î©¶' */
  .uil-lightbulb-alt:before { content: '\ea77'; } /* 'î©·' */
  .uil-lightbulb:before { content: '\ea78'; } /* 'î©¸' */
  .uil-line-alt:before { content: '\ea79'; } /* 'î©¹' */
  .uil-line-spacing:before { content: '\ea7a'; } /* 'î©º' */
  .uil-line:before { content: '\ea7b'; } /* 'î©»' */
  .uil-link-alt:before { content: '\ea7c'; } /* 'î©¼' */
  .uil-link-broken:before { content: '\ea7d'; } /* 'î©½' */
  .uil-link-h:before { content: '\ea7e'; } /* 'î©¾' */
  .uil-link:before { content: '\ea7f'; } /* 'î©¿' */
  .uil-linkedin:before { content: '\ea80'; } /* 'îª€' */
  .uil-list-ui-alt:before { content: '\ea81'; } /* 'îª' */
  .uil-list-ul:before { content: '\ea82'; } /* 'îª‚' */
  .uil-location-arrow-alt:before { content: '\ea83'; } /* 'îªƒ' */
  .uil-location-arrow:before { content: '\ea84'; } /* 'îª„' */
  .uil-location-pin-alt:before { content: '\ea85'; } /* 'îª…' */
  .uil-location-point:before { content: '\ea86'; } /* 'îª†' */
  .uil-location:before { content: '\ea87'; } /* 'îª‡' */
  .uil-lock-access:before { content: '\ea88'; } /* 'îªˆ' */
  .uil-lock-alt:before { content: '\ea89'; } /* 'îª‰' */
  .uil-lock-open-alt:before { content: '\ea8a'; } /* 'îªŠ' */
  .uil-lock-slash:before { content: '\ea8b'; } /* 'îª‹' */
  .uil-lock:before { content: '\ea8c'; } /* 'îªŒ' */
  .uil-mailbox-alt:before { content: '\ea8d'; } /* 'îª' */
  .uil-mailbox:before { content: '\ea8e'; } /* 'îªŽ' */
  .uil-map-marker-alt:before { content: '\ea8f'; } /* 'îª' */
  .uil-map-marker-edit:before { content: '\ea90'; } /* 'îª' */
  .uil-map-marker-info:before { content: '\ea91'; } /* 'îª‘' */
  .uil-map-marker-minus:before { content: '\ea92'; } /* 'îª’' */
  .uil-map-marker-plus:before { content: '\ea93'; } /* 'îª“' */
  .uil-map-marker-question:before { content: '\ea94'; } /* 'îª”' */
  .uil-map-marker-shield:before { content: '\ea95'; } /* 'îª•' */
  .uil-map-marker-slash:before { content: '\ea96'; } /* 'îª–' */
  .uil-map-marker:before { content: '\ea97'; } /* 'îª—' */
  .uil-map-pin-alt:before { content: '\ea98'; } /* 'îª˜' */
  .uil-map-pin:before { content: '\ea99'; } /* 'îª™' */
  .uil-map:before { content: '\ea9a'; } /* 'îªš' */
  .uil-mars:before { content: '\ea9b'; } /* 'îª›' */
  .uil-maximize-left:before { content: '\ea9c'; } /* 'îªœ' */
  .uil-medal:before { content: '\ea9d'; } /* 'îª' */
  .uil-medical-drip:before { content: '\ea9e'; } /* 'îªž' */
  .uil-medical-square-full:before { content: '\ea9f'; } /* 'îªŸ' */
  .uil-medical-square:before { content: '\eaa0'; } /* 'îª ' */
  .uil-medical:before { content: '\eaa1'; } /* 'îª¡' */
  .uil-medkit:before { content: '\eaa2'; } /* 'îª¢' */
  .uil-meeting-board:before { content: '\eaa3'; } /* 'îª£' */
  .uil-meh-alt:before { content: '\eaa4'; } /* 'îª¤' */
  .uil-meh-closed-eye:before { content: '\eaa5'; } /* 'îª¥' */
  .uil-meh:before { content: '\eaa6'; } /* 'îª¦' */
  .uil-message:before { content: '\eaa7'; } /* 'îª§' */
  .uil-microphone-slash:before { content: '\eaa8'; } /* 'îª¨' */
  .uil-microphone:before { content: '\eaa9'; } /* 'îª©' */
  .uil-minus-circle:before { content: '\eaaa'; } /* 'îªª' */
  .uil-minus-path:before { content: '\eaab'; } /* 'îª«' */
  .uil-minus-square-full:before { content: '\eaac'; } /* 'îª¬' */
  .uil-minus-square:before { content: '\eaad'; } /* 'îª­' */
  .uil-minus:before { content: '\eaae'; } /* 'îª®' */
  .uil-missed-call:before { content: '\eaaf'; } /* 'îª¯' */
  .uil-mobey-bill-slash:before { content: '\eab0'; } /* 'îª°' */
  .uil-mobile-android-alt:before { content: '\eab1'; } /* 'îª±' */
  .uil-mobile-android:before { content: '\eab2'; } /* 'îª²' */
  .uil-mobile-vibrate:before { content: '\eab3'; } /* 'îª³' */
  .uil-modem:before { content: '\eab4'; } /* 'îª´' */
  .uil-money-bill-stack:before { content: '\eab5'; } /* 'îªµ' */
  .uil-money-bill:before { content: '\eab6'; } /* 'îª¶' */
  .uil-money-insert:before { content: '\eab7'; } /* 'îª·' */
  .uil-money-stack:before { content: '\eab8'; } /* 'îª¸' */
  .uil-money-withdraw:before { content: '\eab9'; } /* 'îª¹' */
  .uil-money-withdrawal:before { content: '\eaba'; } /* 'îªº' */
  .uil-moneybag-alt:before { content: '\eabb'; } /* 'îª»' */
  .uil-moneybag:before { content: '\eabc'; } /* 'îª¼' */
  .uil-monitor-heart-rate:before { content: '\eabd'; } /* 'îª½' */
  .uil-monitor:before { content: '\eabe'; } /* 'îª¾' */
  .uil-moon-eclipse:before { content: '\eabf'; } /* 'îª¿' */
  .uil-moon:before { content: '\eac0'; } /* 'î«€' */
  .uil-moonset:before { content: '\eac1'; } /* 'î«' */
  .uil-mountains-sun:before { content: '\eac2'; } /* 'î«‚' */
  .uil-mountains:before { content: '\eac3'; } /* 'î«ƒ' */
  .uil-mouse-alt:before { content: '\eac4'; } /* 'î«„' */
  .uil-mouse:before { content: '\eac5'; } /* 'î«…' */
  .uil-multiply:before { content: '\eac6'; } /* 'î«†' */
  .uil-music-note:before { content: '\eac7'; } /* 'î«‡' */
  .uil-music-tune-slash:before { content: '\eac8'; } /* 'î«ˆ' */
  .uil-music:before { content: '\eac9'; } /* 'î«‰' */
  .uil-n-a:before { content: '\eaca'; } /* 'î«Š' */
  .uil-navigator:before { content: '\eacb'; } /* 'î«‹' */
  .uil-nerd:before { content: '\eacc'; } /* 'î«Œ' */
  .uil-newspaper:before { content: '\eacd'; } /* 'î«' */
  .uil-ninja:before { content: '\eace'; } /* 'î«Ž' */
  .uil-no-entry:before { content: '\eacf'; } /* 'î«' */
  .uil-notebooks:before { content: '\ead0'; } /* 'î«' */
  .uil-notes:before { content: '\ead1'; } /* 'î«‘' */
  .uil-object-group:before { content: '\ead2'; } /* 'î«’' */
  .uil-object-ungroup:before { content: '\ead3'; } /* 'î«“' */
  .uil-octagon:before { content: '\ead4'; } /* 'î«”' */
  .uil-outgoing-call:before { content: '\ead5'; } /* 'î«•' */
  .uil-package:before { content: '\ead6'; } /* 'î«–' */
  .uil-padlock:before { content: '\ead7'; } /* 'î«—' */
  .uil-paint-tool:before { content: '\ead8'; } /* 'î«˜' */
  .uil-palette:before { content: '\ead9'; } /* 'î«™' */
  .uil-panorama-h-alt:before { content: '\eada'; } /* 'î«š' */
  .uil-panorama-h:before { content: '\eadb'; } /* 'î«›' */
  .uil-panorama-v:before { content: '\eadc'; } /* 'î«œ' */
  .uil-paperclip:before { content: '\eadd'; } /* 'î«' */
  .uil-paragraph:before { content: '\eade'; } /* 'î«ž' */
  .uil-parcel:before { content: '\eadf'; } /* 'î«Ÿ' */
  .uil-parking-square:before { content: '\eae0'; } /* 'î« ' */
  .uil-pathfinder-unite:before { content: '\eae1'; } /* 'î«¡' */
  .uil-pathfinder:before { content: '\eae2'; } /* 'î«¢' */
  .uil-pause-circle:before { content: '\eae3'; } /* 'î«£' */
  .uil-pause:before { content: '\eae4'; } /* 'î«¤' */
  .uil-pen:before { content: '\eae5'; } /* 'î«¥' */
  .uil-pentagon:before { content: '\eae6'; } /* 'î«¦' */
  .uil-phone-alt:before { content: '\eae7'; } /* 'î«§' */
  .uil-phone-pause:before { content: '\eae8'; } /* 'î«¨' */
  .uil-phone-slash:before { content: '\eae9'; } /* 'î«©' */
  .uil-phone-times:before { content: '\eaea'; } /* 'î«ª' */
  .uil-phone-volume:before { content: '\eaeb'; } /* 'î««' */
  .uil-phone:before { content: '\eaec'; } /* 'î«¬' */
  .uil-picture:before { content: '\eaed'; } /* 'î«­' */
  .uil-plane-arrival:before { content: '\eaee'; } /* 'î«®' */
  .uil-plane-departure:before { content: '\eaef'; } /* 'î«¯' */
  .uil-plane-fly:before { content: '\eaf0'; } /* 'î«°' */
  .uil-plane:before { content: '\eaf1'; } /* 'î«±' */
  .uil-play-circle:before { content: '\eaf2'; } /* 'î«²' */
  .uil-play:before { content: '\eaf3'; } /* 'î«³' */
  .uil-plug:before { content: '\eaf4'; } /* 'î«´' */
  .uil-plus-circle:before { content: '\eaf5'; } /* 'î«µ' */
  .uil-plus-square:before { content: '\eaf6'; } /* 'î«¶' */
  .uil-plus:before { content: '\eaf7'; } /* 'î«·' */
  .uil-podium:before { content: '\eaf8'; } /* 'î«¸' */
  .uil-polygon:before { content: '\eaf9'; } /* 'î«¹' */
  .uil-post-stamp:before { content: '\eafa'; } /* 'î«º' */
  .uil-postcard:before { content: '\eafb'; } /* 'î«»' */
  .uil-pound-circle:before { content: '\eafc'; } /* 'î«¼' */
  .uil-pound:before { content: '\eafd'; } /* 'î«½' */
  .uil-power:before { content: '\eafe'; } /* 'î«¾' */
  .uil-prescription-bottle:before { content: '\eaff'; } /* 'î«¿' */
  .uil-presentation-check:before { content: '\eb00'; } /* 'î¬€' */
  .uil-presentation-edit:before { content: '\eb01'; } /* 'î¬' */
  .uil-presentation-line:before { content: '\eb02'; } /* 'î¬‚' */
  .uil-presentation-lines-alt:before { content: '\eb03'; } /* 'î¬ƒ' */
  .uil-presentation-minus:before { content: '\eb04'; } /* 'î¬„' */
  .uil-presentation-play:before { content: '\eb05'; } /* 'î¬…' */
  .uil-presentation-plus:before { content: '\eb06'; } /* 'î¬†' */
  .uil-presentation-times:before { content: '\eb07'; } /* 'î¬‡' */
  .uil-presentation:before { content: '\eb08'; } /* 'î¬ˆ' */
  .uil-previous:before { content: '\eb09'; } /* 'î¬‰' */
  .uil-pricetag-alt:before { content: '\eb0a'; } /* 'î¬Š' */
  .uil-print-slash:before { content: '\eb0b'; } /* 'î¬‹' */
  .uil-print:before { content: '\eb0c'; } /* 'î¬Œ' */
  .uil-processor:before { content: '\eb0d'; } /* 'î¬' */
  .uil-pump:before { content: '\eb0e'; } /* 'î¬Ž' */
  .uil-puzzle-piece:before { content: '\eb0f'; } /* 'î¬' */
  .uil-question-circle:before { content: '\eb10'; } /* 'î¬' */
  .uil-rainbow:before { content: '\eb11'; } /* 'î¬‘' */
  .uil-raindrops-alt:before { content: '\eb12'; } /* 'î¬’' */
  .uil-raindrops:before { content: '\eb13'; } /* 'î¬“' */
  .uil-receipt-alt:before { content: '\eb14'; } /* 'î¬”' */
  .uil-receipt:before { content: '\eb15'; } /* 'î¬•' */
  .uil-record-audio:before { content: '\eb16'; } /* 'î¬–' */
  .uil-redo:before { content: '\eb17'; } /* 'î¬—' */
  .uil-refresh:before { content: '\eb18'; } /* 'î¬˜' */
  .uil-registered:before { content: '\eb19'; } /* 'î¬™' */
  .uil-repeat:before { content: '\eb1a'; } /* 'î¬š' */
  .uil-restaurant:before { content: '\eb1b'; } /* 'î¬›' */
  .uil-right-indent-alt:before { content: '\eb1c'; } /* 'î¬œ' */
  .uil-right-to-left-text-direction:before { content: '\eb1d'; } /* 'î¬' */
  .uil-robot:before { content: '\eb1e'; } /* 'î¬ž' */
  .uil-rope-way:before { content: '\eb1f'; } /* 'î¬Ÿ' */
  .uil-rotate-360:before { content: '\eb20'; } /* 'î¬ ' */
  .uil-rss-alt:before { content: '\eb21'; } /* 'î¬¡' */
  .uil-rss-interface:before { content: '\eb22'; } /* 'î¬¢' */
  .uil-rss:before { content: '\eb23'; } /* 'î¬£' */
  .uil-ruler-combined:before { content: '\eb24'; } /* 'î¬¤' */
  .uil-ruler:before { content: '\eb25'; } /* 'î¬¥' */
  .uil-sad-cry:before { content: '\eb26'; } /* 'î¬¦' */
  .uil-sad-crying:before { content: '\eb27'; } /* 'î¬§' */
  .uil-sad-dizzy:before { content: '\eb28'; } /* 'î¬¨' */
  .uil-sad-squint:before { content: '\eb29'; } /* 'î¬©' */
  .uil-sad:before { content: '\eb2a'; } /* 'î¬ª' */
  .uil-scaling-left:before { content: '\eb2b'; } /* 'î¬«' */
  .uil-scaling-right:before { content: '\eb2c'; } /* 'î¬¬' */
  .uil-scenery:before { content: '\eb2d'; } /* 'î¬­' */
  .uil-schedule:before { content: '\eb2e'; } /* 'î¬®' */
  .uil-science:before { content: '\eb2f'; } /* 'î¬¯' */
  .uil-screw:before { content: '\eb30'; } /* 'î¬°' */
  .uil-scroll-h:before { content: '\eb31'; } /* 'î¬±' */
  .uil-scroll:before { content: '\eb32'; } /* 'î¬²' */
  .uil-search-alt:before { content: '\eb33'; } /* 'î¬³' */
  .uil-search-minus:before { content: '\eb34'; } /* 'î¬´' */
  .uil-search-plus:before { content: '\eb35'; } /* 'î¬µ' */
  .uil-search:before { content: '\eb36'; } /* 'î¬¶' */
  .uil-selfie:before { content: '\eb37'; } /* 'î¬·' */
  .uil-server-alt:before { content: '\eb38'; } /* 'î¬¸' */
  .uil-server-connection:before { content: '\eb39'; } /* 'î¬¹' */
  .uil-server-network-alt:before { content: '\eb3a'; } /* 'î¬º' */
  .uil-server-network:before { content: '\eb3b'; } /* 'î¬»' */
  .uil-server:before { content: '\eb3c'; } /* 'î¬¼' */
  .uil-servers:before { content: '\eb3d'; } /* 'î¬½' */
  .uil-servicemark:before { content: '\eb3e'; } /* 'î¬¾' */
  .uil-share-alt:before { content: '\eb3f'; } /* 'î¬¿' */
  .uil-shield-check:before { content: '\eb40'; } /* 'î­€' */
  .uil-shield-exclamation:before { content: '\eb41'; } /* 'î­' */
  .uil-shield-question:before { content: '\eb42'; } /* 'î­‚' */
  .uil-shield-slash:before { content: '\eb43'; } /* 'î­ƒ' */
  .uil-shield:before { content: '\eb44'; } /* 'î­„' */
  .uil-ship:before { content: '\eb45'; } /* 'î­…' */
  .uil-shop:before { content: '\eb46'; } /* 'î­†' */
  .uil-shopping-basket:before { content: '\eb47'; } /* 'î­‡' */
  .uil-shopping-cart-alt:before { content: '\eb48'; } /* 'î­ˆ' */
  .uil-shopping-trolley:before { content: '\eb49'; } /* 'î­‰' */
  .uil-shovel:before { content: '\eb4a'; } /* 'î­Š' */
  .uil-shrink:before { content: '\eb4b'; } /* 'î­‹' */
  .uil-shuffle:before { content: '\eb4c'; } /* 'î­Œ' */
  .uil-shutter-alt:before { content: '\eb4d'; } /* 'î­' */
  .uil-shutter:before { content: '\eb4e'; } /* 'î­Ž' */
  .uil-sick:before { content: '\eb4f'; } /* 'î­' */
  .uil-sigma:before { content: '\eb50'; } /* 'î­' */
  .uil-sign-alt:before { content: '\eb51'; } /* 'î­‘' */
  .uil-sign-in-alt:before { content: '\eb52'; } /* 'î­’' */
  .uil-sign-left:before { content: '\eb53'; } /* 'î­“' */
  .uil-sign-out-alt:before { content: '\eb54'; } /* 'î­”' */
  .uil-sign-right:before { content: '\eb55'; } /* 'î­•' */
  .uil-signal-alt-3:before { content: '\eb56'; } /* 'î­–' */
  .uil-signal-alt:before { content: '\eb57'; } /* 'î­—' */
  .uil-signal:before { content: '\eb58'; } /* 'î­˜' */
  .uil-silence:before { content: '\eb59'; } /* 'î­™' */
  .uil-silent-squint:before { content: '\eb5a'; } /* 'î­š' */
  .uil-sim-card:before { content: '\eb5b'; } /* 'î­›' */
  .uil-sitemap:before { content: '\eb5c'; } /* 'î­œ' */
  .uil-skip-forward-alt:before { content: '\eb5d'; } /* 'î­' */
  .uil-skip-forward-circle:before { content: '\eb5e'; } /* 'î­ž' */
  .uil-skip-forward:before { content: '\eb5f'; } /* 'î­Ÿ' */
  .uil-slack:before { content: '\eb60'; } /* 'î­ ' */
  .uil-sliders-v-alt:before { content: '\eb61'; } /* 'î­¡' */
  .uil-sliders-v:before { content: '\eb62'; } /* 'î­¢' */
  .uil-smile-beam:before { content: '\eb63'; } /* 'î­£' */
  .uil-smile-dizzy:before { content: '\eb64'; } /* 'î­¤' */
  .uil-smile-squint-wink-alt:before { content: '\eb65'; } /* 'î­¥' */
  .uil-smile-squint-wink:before { content: '\eb66'; } /* 'î­¦' */
  .uil-smile-wink-alt:before { content: '\eb67'; } /* 'î­§' */
  .uil-smile-wink:before { content: '\eb68'; } /* 'î­¨' */
  .uil-smile:before { content: '\eb69'; } /* 'î­©' */
  .uil-snow-flake:before { content: '\eb6c'; } /* 'î­¬' */
  .uil-snowflake-alt:before { content: '\eb6d'; } /* 'î­­' */
  .uil-snowflake:before { content: '\eb6e'; } /* 'î­®' */
  .uil-sort-amount-down:before { content: '\eb6f'; } /* 'î­¯' */
  .uil-sort-amount-up:before { content: '\eb70'; } /* 'î­°' */
  .uil-sort:before { content: '\eb71'; } /* 'î­±' */
  .uil-sorting:before { content: '\eb72'; } /* 'î­²' */
  .uil-space-key:before { content: '\eb73'; } /* 'î­³' */
  .uil-spade:before { content: '\eb74'; } /* 'î­´' */
  .uil-sperms:before { content: '\eb75'; } /* 'î­µ' */
  .uil-spin:before { content: '\eb76'; } /* 'î­¶' */
  .uil-sport:before { content: '\eb77'; } /* 'î­·' */
  .uil-square-full:before { content: '\eb78'; } /* 'î­¸' */
  .uil-square-shape:before { content: '\eb79'; } /* 'î­¹' */
  .uil-square:before { content: '\eb7a'; } /* 'î­º' */
  .uil-squint:before { content: '\eb7b'; } /* 'î­»' */
  .uil-star-half-alt:before { content: '\eb7c'; } /* 'î­¼' */
  .uil-star:before { content: '\eb7d'; } /* 'î­½' */
  .uil-step-backward-alt:before { content: '\eb7e'; } /* 'î­¾' */
  .uil-step-backward-circle:before { content: '\eb7f'; } /* 'î­¿' */
  .uil-step-backward:before { content: '\eb80'; } /* 'î®€' */
  .uil-step-forward:before { content: '\eb81'; } /* 'î®' */
  .uil-stop-circle:before { content: '\eb82'; } /* 'î®‚' */
  .uil-stopwatch-slash:before { content: '\eb83'; } /* 'î®ƒ' */
  .uil-stopwatch:before { content: '\eb84'; } /* 'î®„' */
  .uil-store-alt:before { content: '\eb85'; } /* 'î®…' */
  .uil-store:before { content: '\eb86'; } /* 'î®†' */
  .uil-streering:before { content: '\eb87'; } /* 'î®‡' */
  .uil-stretcher:before { content: '\eb88'; } /* 'î®ˆ' */
  .uil-subject:before { content: '\eb89'; } /* 'î®‰' */
  .uil-subway-alt:before { content: '\eb8a'; } /* 'î®Š' */
  .uil-subway:before { content: '\eb8b'; } /* 'î®‹' */
  .uil-suitcase-alt:before { content: '\eb8c'; } /* 'î®Œ' */
  .uil-suitcase:before { content: '\eb8d'; } /* 'î®' */
  .uil-sun:before { content: '\eb8e'; } /* 'î®Ž' */
  .uil-sunset:before { content: '\eb8f'; } /* 'î®' */
  .uil-surprise:before { content: '\eb90'; } /* 'î®' */
  .uil-swatchbook:before { content: '\eb91'; } /* 'î®‘' */
  .uil-swimmer:before { content: '\eb92'; } /* 'î®’' */
  .uil-symbol:before { content: '\eb93'; } /* 'î®“' */
  .uil-sync-exclamation:before { content: '\eb94'; } /* 'î®”' */
  .uil-sync-slash:before { content: '\eb95'; } /* 'î®•' */
  .uil-sync:before { content: '\eb96'; } /* 'î®–' */
  .uil-syringe:before { content: '\eb97'; } /* 'î®—' */
  .uil-table:before { content: '\eb98'; } /* 'î®˜' */
  .uil-tablet:before { content: '\eb99'; } /* 'î®™' */
  .uil-tablets:before { content: '\eb9a'; } /* 'î®š' */
  .uil-tachometer-fast:before { content: '\eb9b'; } /* 'î®›' */
  .uil-tag-alt:before { content: '\eb9c'; } /* 'î®œ' */
  .uil-tag:before { content: '\eb9d'; } /* 'î®' */
  .uil-tape:before { content: '\eb9e'; } /* 'î®ž' */
  .uil-taxi:before { content: '\eb9f'; } /* 'î®Ÿ' */
  .uil-tear:before { content: '\eba0'; } /* 'î® ' */
  .uil-technology:before { content: '\eba1'; } /* 'î®¡' */
  .uil-telescope:before { content: '\eba2'; } /* 'î®¢' */
  .uil-temperature-empty:before { content: '\eba3'; } /* 'î®£' */
  .uil-temperature-half:before { content: '\eba4'; } /* 'î®¤' */
  .uil-temperature-minus:before { content: '\eba5'; } /* 'î®¥' */
  .uil-temperature-plus:before { content: '\eba6'; } /* 'î®¦' */
  .uil-temperature-quarter:before { content: '\eba7'; } /* 'î®§' */
  .uil-temperature-three-quarter:before { content: '\eba8'; } /* 'î®¨' */
  .uil-temperature:before { content: '\eba9'; } /* 'î®©' */
  .uil-text-fields:before { content: '\ebaa'; } /* 'î®ª' */
  .uil-text-size:before { content: '\ebab'; } /* 'î®«' */
  .uil-text-strike-through:before { content: '\ebac'; } /* 'î®¬' */
  .uil-text:before { content: '\ebad'; } /* 'î®­' */
  .uil-th-large:before { content: '\ebae'; } /* 'î®®' */
  .uil-th-slash:before { content: '\ebaf'; } /* 'î®¯' */
  .uil-th:before { content: '\ebb0'; } /* 'î®°' */
  .uil-thermometer:before { content: '\ebb1'; } /* 'î®±' */
  .uil-thumbs-down:before { content: '\ebb2'; } /* 'î®²' */
  .uil-thumbs-up:before { content: '\ebb3'; } /* 'î®³' */
  .uil-thunderstorm-moon:before { content: '\ebb4'; } /* 'î®´' */
  .uil-thunderstorm-sun:before { content: '\ebb5'; } /* 'î®µ' */
  .uil-thunderstorm:before { content: '\ebb6'; } /* 'î®¶' */
  .uil-ticket:before { content: '\ebb7'; } /* 'î®·' */
  .uil-times-circle:before { content: '\ebb8'; } /* 'î®¸' */
  .uil-times-square:before { content: '\ebb9'; } /* 'î®¹' */
  .uil-times:before { content: '\ebba'; } /* 'î®º' */
  .uil-toggle-off:before { content: '\ebbb'; } /* 'î®»' */
  .uil-toggle-on:before { content: '\ebbc'; } /* 'î®¼' */
  .uil-top-arrow-from-top:before { content: '\ebbd'; } /* 'î®½' */
  .uil-top-arrow-to-top:before { content: '\ebbe'; } /* 'î®¾' */
  .uil-tornado:before { content: '\ebbf'; } /* 'î®¿' */
  .uil-trademark-circle:before { content: '\ebc0'; } /* 'î¯€' */
  .uil-trademark:before { content: '\ebc1'; } /* 'î¯' */
  .uil-traffic-barrier:before { content: '\ebc2'; } /* 'î¯‚' */
  .uil-trash-alt:before { content: '\ebc3'; } /* 'î¯ƒ' */
  .uil-trash:before { content: '\ebc4'; } /* 'î¯„' */
  .uil-trees:before { content: '\ebc5'; } /* 'î¯…' */
  .uil-triangle:before { content: '\ebc6'; } /* 'î¯†' */
  .uil-trophy:before { content: '\ebc7'; } /* 'î¯‡' */
  .uil-trowel:before { content: '\ebc8'; } /* 'î¯ˆ' */
  .uil-truck-case:before { content: '\ebc9'; } /* 'î¯‰' */
  .uil-truck-loading:before { content: '\ebca'; } /* 'î¯Š' */
  .uil-truck:before { content: '\ebcb'; } /* 'î¯‹' */
  .uil-tumblr-square:before { content: '\ebcc'; } /* 'î¯Œ' */
  .uil-tumblr:before { content: '\ebcd'; } /* 'î¯' */
  .uil-tv-retro-slash:before { content: '\ebce'; } /* 'î¯Ž' */
  .uil-tv-retro:before { content: '\ebcf'; } /* 'î¯' */
  .uil-twitter:before { content: '\ebd0'; } /* 'î¯' */
  .uil-umbrella:before { content: '\ebd1'; } /* 'î¯‘' */
  .uil-unamused:before { content: '\ebd2'; } /* 'î¯’' */
  .uil-underline:before { content: '\ebd3'; } /* 'î¯“' */
  .uil-unlock-alt:before { content: '\ebd4'; } /* 'î¯”' */
  .uil-unlock:before { content: '\ebd5'; } /* 'î¯•' */
  .uil-upload-alt:before { content: '\ebd6'; } /* 'î¯–' */
  .uil-upload:before { content: '\ebd7'; } /* 'î¯—' */
  .uil-usd-circle:before { content: '\ebd8'; } /* 'î¯˜' */
  .uil-usd-square:before { content: '\ebd9'; } /* 'î¯™' */
  .uil-user-check:before { content: '\ebda'; } /* 'î¯š' */
  .uil-user-circle:before { content: '\ebdb'; } /* 'î¯›' */
  .uil-user-exclamation:before { content: '\ebdc'; } /* 'î¯œ' */
  .uil-user-hard-hat:before { content: '\ebdd'; } /* 'î¯' */
  .uil-user-minus:before { content: '\ebde'; } /* 'î¯ž' */
  .uil-user-plus:before { content: '\ebdf'; } /* 'î¯Ÿ' */
  .uil-user-square:before { content: '\ebe0'; } /* 'î¯ ' */
  .uil-user-times:before { content: '\ebe1'; } /* 'î¯¡' */
  .uil-user:before { content: '\ebe2'; } /* 'î¯¢' */
  .uil-users-alt:before { content: '\ebe3'; } /* 'î¯£' */
  .uil-utensils-alt:before { content: '\ebe4'; } /* 'î¯¤' */
  .uil-utensils:before { content: '\ebe5'; } /* 'î¯¥' */
  .uil-vector-square-alt:before { content: '\ebe6'; } /* 'î¯¦' */
  .uil-vector-square:before { content: '\ebe7'; } /* 'î¯§' */
  .uil-venus:before { content: '\ebe8'; } /* 'î¯¨' */
  .uil-vertical-align-bottom:before { content: '\ebe9'; } /* 'î¯©' */
  .uil-vertical-align-center:before { content: '\ebea'; } /* 'î¯ª' */
  .uil-vertical-align-top:before { content: '\ebeb'; } /* 'î¯«' */
  .uil-vertical-distribute-bottom:before { content: '\ebec'; } /* 'î¯¬' */
  .uil-vertical-distribution-center:before { content: '\ebed'; } /* 'î¯­' */
  .uil-vertical-distribution-top:before { content: '\ebee'; } /* 'î¯®' */
  .uil-video-slash:before { content: '\ebef'; } /* 'î¯¯' */
  .uil-video:before { content: '\ebf0'; } /* 'î¯°' */
  .uil-visual-studio:before { content: '\ebf1'; } /* 'î¯±' */
  .uil-voicemail-rectangle:before { content: '\ebf2'; } /* 'î¯²' */
  .uil-voicemail:before { content: '\ebf3'; } /* 'î¯³' */
  .uil-volleyball:before { content: '\ebf4'; } /* 'î¯´' */
  .uil-volume-down:before { content: '\ebf5'; } /* 'î¯µ' */
  .uil-volume-mute:before { content: '\ebf6'; } /* 'î¯¶' */
  .uil-volume-off:before { content: '\ebf7'; } /* 'î¯·' */
  .uil-volume-up:before { content: '\ebf8'; } /* 'î¯¸' */
  .uil-volume:before { content: '\ebf9'; } /* 'î¯¹' */
  .uil-wall:before { content: '\ebfa'; } /* 'î¯º' */
  .uil-wallet:before { content: '\ebfb'; } /* 'î¯»' */
  .uil-watch-alt:before { content: '\ebfc'; } /* 'î¯¼' */
  .uil-watch:before { content: '\ebfd'; } /* 'î¯½' */
  .uil-water-drop-slash:before { content: '\ebfe'; } /* 'î¯¾' */
  .uil-water-glass:before { content: '\ebff'; } /* 'î¯¿' */
  .uil-water:before { content: '\ec00'; } /* 'î°€' */
  .uil-web-grid-alt:before { content: '\ec01'; } /* 'î°' */
  .uil-web-grid:before { content: '\ec02'; } /* 'î°‚' */
  .uil-web-section-alt:before { content: '\ec03'; } /* 'î°ƒ' */
  .uil-web-section:before { content: '\ec04'; } /* 'î°„' */
  .uil-webcam:before { content: '\ec05'; } /* 'î°…' */
  .uil-weight:before { content: '\ec06'; } /* 'î°†' */
  .uil-whatsapp:before { content: '\ec07'; } /* 'î°‡' */
  .uil-wheel-barrow:before { content: '\ec08'; } /* 'î°ˆ' */
  .uil-wheelchair-alt:before { content: '\ec09'; } /* 'î°‰' */
  .uil-wheelchair:before { content: '\ec0a'; } /* 'î°Š' */
  .uil-wifi-router:before { content: '\ec0b'; } /* 'î°‹' */
  .uil-wifi-slash:before { content: '\ec0c'; } /* 'î°Œ' */
  .uil-wifi:before { content: '\ec0d'; } /* 'î°' */
  .uil-wind:before { content: '\ec0e'; } /* 'î°Ž' */
  .uil-window-grid:before { content: '\ec0f'; } /* 'î°' */
  .uil-window-maximize:before { content: '\ec10'; } /* 'î°' */
  .uil-window-restore:before { content: '\ec11'; } /* 'î°‘' */
  .uil-window-section:before { content: '\ec12'; } /* 'î°’' */
  .uil-window:before { content: '\ec13'; } /* 'î°“' */
  .uil-windsock:before { content: '\ec14'; } /* 'î°”' */
  .uil-wrap-text:before { content: '\ec15'; } /* 'î°•' */
  .uil-wrench:before { content: '\ec16'; } /* 'î°–' */
  .uil-yellow:before { content: '\ec17'; } /* 'î°—' */
  .uil-yen-circle:before { content: '\ec18'; } /* 'î°˜' */
  .uil-yen:before { content: '\ec19'; } /* 'î°™' */
  .uil-youtube-alt:before { content: '\ec1a'; } /* 'î°š' */
  .uil-youtube:before { content: '\ec1b'; } /* 'î°›' */